<template>
    <section fluid>
      <v-data-table
      :headers="headers"
      :items="proveedores"
      item-value="correlativo"
      hide-default-footer
      no-data-text="No hay datos para mostrar"
      :items-per-page="-1"
    >
      <template v-slot:[`item.correlativo`]="{ item }">
        {{ proveedores.map((col) => col.id).indexOf(item.id) + 1 }}
      </template>
    </v-data-table>
  </section>
</template>
  
  <script>
  export default {
    name: "ProveedorComponent",
    data() {
      return {
          proveedores:[],
          headers:[
              {
                  text:"Correlativo",
                  align:"center",
                  value:"correlativo"
              },
              {
                  text:"Proveedor",
                  value:"nombre"
              }
          ]
      };
    },
    methods: {
      async getProveedores() {
        const res = await this.services.SolicitudCompra.getSolicitudesProveedoresInsumo(
            this.$route.params.idSolicitud
        )
        this.proveedores = res.data
      },
    },
    async created(){
        await this.getProveedores()
    },
    // beforeDestroy() {
    //   this.setProveedoresList([]);
    // },
  };
  </script>