<template>
  <section fluid>
    <data-table-component
      :headers="encabezadosProcesoCompra"
      :items="insumosList"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters.total_rows"
      :show_loading="loadingData"
      @paginar="paginar"
      mobile_breakpoint="950"
    >
      <template v-slot:[`item.unidad_medida`]="{ item }">
        {{
          item.unidad_medida || item.unidad_medida_proveedor

        }}
      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.total)
        }}
      </template>
      <template v-slot:[`item.precio_unitario`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.precio_unitario)
        }}
      </template>
      <template v-slot:[`item.cantidad`]="{ item }">
        {{
          item.cantidad
        }}
      </template>
        <template v-slot:[`item.total_aprobar`]="{ item }">
        {{
            Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.total_aprobado)
        }}
      </template>
    </data-table-component>
  </section>
</template>

<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
export default {
  name: "insumoComponent",
  components: { DataTableComponent },
  data() {
    return {
      insumos: [],
      encabezadosProcesoCompra: [
        {
          text: "OBS",
          align: "center",
          value: "obs",
        },
        {
          text: "Especificación",
          align: "center",
          value: "observacion",
        },
        {
          text: "Unidad de medida",
          align: "center",
          value: "unidad_medida",
        },
        {
          text: "Cantidad solicitada",
          align: "center",
          value: "cantidad",
        },
        {
          text: "Cantidad aprobada",
          align: "center",
          value: "cantidad_aprobar",
        },
        {
          text: "Total ($)",
          align: "center",
          value: "total",
        },
        {
          text: "Precio unitario ($)",
          align: "center",
          value: "precio_unitario",
        },
      ],
      loadingData: false,
      insumosList: [],
      filters: {
        page: 1,
        per_page: 10,
        pagination: true,
        total_rows: null,
      },
    };
  },
  computed: {
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
  },
  methods: {
    async getInsumos() {
      this.loadingData = true;
      const res =
        await this.services.SolicitudCompra.getSolicitudesCompraInsumos(
          this.$route.params.idSolicitud,
          this.filters
        );
            this.insumosList = res.data.map((insumo) => {
          return {
            obs: insumo.obs,
            observacion : insumo.observacion,
            unidad_medida : insumo.unidad_medida ? insumo.unidad_medida : insumo.unidad_medida_proveedor,
            cantidad : insumo.cantidad,
            cantidad_aprobar : insumo.solicitud_compra_detalle_obs[0].cantidad_aprobar,
            total : insumo.total,
            precio_unitario : insumo.precio_unitario
          }
      })
      this.filters.page = Number(res.headers.page);
      this.filters.per_page = Number(res.headers.per_page);
      this.filters.total_rows = Number(res.headers.total_rows);
      setTimeout(() => this.loadingData = false, 200);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getInsumos();
    },
  },
  async created() {
    await this.getInsumos();
  },
};
</script>
