<template>
    <section fluid class="my-5">
        <v-tabs v-model="tabsMenu" background-color="bgMinsal" show-arrows>
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab
                href="#tab-1"
                class="secondary--text v-step-0"
            ><v-icon>mdi-package-variant-closed </v-icon>Insumos</v-tab>
            <v-tab
                href="#tab-2"
                class="secondary--text v-step-1"
                v-if="['2','3'].includes($route.params.tipo)"
            ><v-icon>mdi-account-multiple </v-icon>Proveedores</v-tab>
        </v-tabs>
        <v-tabs-items style="width: 100%" v-model="tabsMenu" class="v-step-2">
            <v-tab-item value="tab-1">
                <v-card color="bgMinsal" class="mt-6">
                    <h6 class="text-h6 font-museo-sans primary--text mx-4 mt-4">Listados de insumos</h6>
                    <insumos class="py-7 px-3"/>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2" class="tabStyle">
                <v-card color="bgMinsal" class="mt-6">
                    <h6 class="text-h6 font-museo-sans primary--text mx-4 mt-4">Listados de proveedores</h6>
                    <proveedores />
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <div>
            <v-btn class="mt-10 mx-4 text-capitalize button-extra" outlined @click="goBack()"> Volver </v-btn>
        </div>
    </section>
</template>

<script>
import insumos from "./components/insumoComponent.vue";
import proveedores from "./components/proveedorComponent.vue";
export default {
    name:"detalleSolicitud",
    components: {
        insumos,
        proveedores,
    },
    data() {
        return {
            tabsMenu:null,
        }
    },
    methods: {
      goBack() {
        this.$router.push("/solicitudes-compra");
      },
    }
}
</script>